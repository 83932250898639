import { put } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';

import {
  bettingTimeStartedAction,
  changeTimeLeftAction,
  changeGameStateAction,
  changeGameStatusAction,
  startDecrementTimeLeftAction,
} from 'data/reducers';
import { BettingTimeStartedResponse, Notifications, TableStates } from 'types';
import { gameStatus } from 'constants/common';
import { TimerTypes } from 'types/tables';

export function* bettingTimeStarted({
  type: notificationType,
  data: result,
}: BettingTimeStartedResponse) {
  try {
    if (notificationType === Notifications.BETTING_TIME_STARTED) {
      const { bettingTime, finishBettingTime } = result;

      yield put(changeGameStatusAction({ status: gameStatus.BettingTime }));
      yield put(changeGameStateAction({ tableState: TableStates.BettingTimeStarted }));
      yield put(bettingTimeStartedAction({ bettingTime, finishBettingTime }));
      yield put(changeTimeLeftAction({ timerLeft: bettingTime }));
      yield put(startDecrementTimeLeftAction({ type: TimerTypes.bettingTime }));
    }
  } catch (error) {
    Sentry.captureException(error);
  }
}
