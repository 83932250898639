import { Icon } from 'components/atoms';
import fontService, { FontWeight } from 'services/FontService';
import styled, { css } from 'styled-components';
import { GameType, ResultBall } from 'types';

export const Wrapper = styled.div<{ value?: string }>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
  /* #12101100, #991414 */
  ${({ value }) => {
    if (value === ResultBall.BLUE) {
      return css`
        background: linear-gradient(135deg, rgba(18, 16, 17, 0) 0%, rgba(0, 111, 156, 0.8) 92.13%),
          linear-gradient(180deg, rgba(18, 16, 17, 0.6) 0%, #121011 86.04%);
      `;
    }

    if (value === ResultBall.RED) {
      return css`
        background: linear-gradient(135deg, rgba(18, 16, 17, 0) 0%, rgba(153, 20, 20, 0.8) 92.13%),
          linear-gradient(180deg, rgba(18, 16, 17, 0.6) 0%, #121011 86.04%);
      `;
    }

    if (value === ResultBall.GOLD) {
      return css`
        background: linear-gradient(135deg, rgba(18, 16, 17, 0) 0%, rgba(174, 150, 0, 0.8) 92.13%),
          linear-gradient(180deg, rgba(18, 16, 17, 0.6) 0%, #121011 86.04%);
      `;
    }

    return css`
      background: linear-gradient(180deg, rgba(18, 16, 17, 0.6) 0%, #121011 86.04%);
    `;
  }};
`;

export const ResultValueBG = styled(Icon)`
  position: absolute;
  top: -66px;
  left: 0;
  height: 100%;
  width: 100%;
`;

export const ResultValue = styled(Icon)`
  position: relative;
  top: -56px;
  width: 210px;
  height: 210px;
`;

export const ResultLabel = styled.p`
  position: relative;
  top: -36px;
  ${fontService.getFont({ size: 48, lineHeight: 116, weight: FontWeight.bold })};
  ${({ theme: { text } }) => css`
    color: ${text.primaryDefault};
  `};
`;

export const JPWinner = styled.div<{ gameType: GameType }>`
  display: inline-flex;
  padding: 32px 40px;
  justify-content: center;
  align-items: center;
  gap: 24px;
  border-radius: 8px;
  background: #7107aa;
  position: absolute;
  z-index: 10;
  top: ${({ gameType }) => (gameType === GameType.BAC ? '46%' : '38%')};
  left: 50%;
  min-width: 520px;
  transform: translateX(-50%) translateY(-50%);

  box-shadow: 0px 6px 16px 0px rgba(18, 16, 17, 0.64);

  color: var(--off-white-fff-7-eb, #fff7eb);
  text-align: center;
  font-family: 'HalvarBreit';
  font-size: 30px;
  font-style: normal;
  font-weight: 800;
  line-height: 24px; /* 80% */
  letter-spacing: 0.6px;
  text-transform: uppercase;
  /* text-align: center; */

  svg {
    margin-right: -20px;
  }
`;
