import React from 'react';

import { ResultBall, ResultDice } from 'types';
import { Dice, IconNames } from 'atoms';
import {
  Result,
  ResultLabel,
  ResultsWrap,
  ResultValue,
  ResultValueBG,
  Wrapper,
  TripleText,
} from './styles';

export interface IProps {
  values?: ResultDice[];
  showBg: boolean;
  isTriple: boolean;
}

export const DrawResultDices = ({ values, showBg, isTriple }: IProps) => (
  <Wrapper>
    {isTriple && <TripleText>Triple</TripleText>}
    {showBg ? <ResultValueBG name={IconNames.BallBackground} /> : null}
    <ResultsWrap>
      {values?.length !== 0
        ? values?.map((value, index) => (
            <Result key={index}>
              <ResultValue>
                <Dice value={value} />
              </ResultValue>
              <ResultLabel>{value === ResultDice.UNDEFINED ? 'Not Determined' : value}</ResultLabel>
            </Result>
          ))
        : [...Array(3)].map((_, index) => (
            <Result key={index}>
              <ResultValue>
                <Dice value={ResultBall.UNDEFINED} />
              </ResultValue>
            </Result>
          ))}
    </ResultsWrap>
  </Wrapper>
);
