import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getGameData } from 'core/selectors';
import { startRoundAction, startDrawAction, confirmResultAction } from 'data/reducers';

import { TableStates, ResultBall, ResultDice } from 'types';
import { IEnabledButton } from 'types/common';

type ButtonControlsHookType = {
  enabledButtons: IEnabledButton;
  isChangeDealerEnabled: boolean;
  onOpenRoundClick: () => void;
  onStartDrawClick: () => void;
  onConfirmResultClick: () => void;
};

export const useButtonControls = (): ButtonControlsHookType => {
  const dispatch = useDispatch();

  const {
    auto_confirm_result: autoConfirmResult,
    auto_draw: autoDraw,
    auto_round: autoRound,
    tableState,
    round: { drawResults, ballWinner, diceWinners },
  } = useSelector(getGameData);

  const isButtonConfirmResultEnabled = useMemo(
    () =>
      autoConfirmResult
        ? false
        : !(
            tableState !== TableStates.DrawFinished ||
            ballWinner === ResultBall.UNDEFINED ||
            (diceWinners && Object.values(diceWinners).includes(ResultDice.UNDEFINED))
          ),
    [ballWinner, diceWinners, tableState, autoConfirmResult],
  );

  const isButtonChangeResultEnabled = useMemo(
    () => tableState === TableStates.DrawFinished || tableState === TableStates.DrawStarted,
    [tableState],
  );

  const isButtonCancelRoundEnabled = useMemo(
    () =>
      tableState === TableStates.RoundStarted ||
      tableState === TableStates.BettingTimeStarted ||
      tableState === TableStates.BettingTimeFinished ||
      tableState === TableStates.DrawStarted ||
      tableState === TableStates.DrawFinished,
    [tableState],
  );

  const enabledButtonDuringRounds = useMemo(
    () =>
      tableState === TableStates.TableOpened ||
      tableState === TableStates.RoundFinished ||
      tableState === TableStates.RoundCanceled,
    [tableState],
  );

  const enabledButtonAfterCancelRound = useMemo(
    () => tableState === TableStates.RoundCanceled,
    [tableState],
  );

  const isButtonOpenRoundEnabled = useMemo(() => {
    if (autoRound) {
      if (drawResults.length) {
        return enabledButtonAfterCancelRound;
      }
      return enabledButtonDuringRounds;
    }
    return enabledButtonDuringRounds;
  }, [autoRound, drawResults, enabledButtonDuringRounds, enabledButtonAfterCancelRound]);

  const isButtonStartDrawEnabled = useMemo(
    () => (autoDraw ? false : tableState === TableStates.BettingTimeFinished),
    [tableState, autoDraw],
  );

  const isChangeDealerEnabled = useMemo(
    () =>
      tableState === TableStates.TableOpened ||
      tableState === TableStates.RoundFinished ||
      tableState === TableStates.BettingTimeStarted ||
      tableState === TableStates.RoundStarted ||
      tableState === TableStates.RoundCanceled,
    [tableState],
  );

  const onOpenRoundClick = useCallback(() => {
    dispatch(startRoundAction());
  }, [dispatch]);

  const onStartDrawClick = useCallback(() => {
    dispatch(startDrawAction());
  }, [dispatch]);

  const onConfirmResultClick = useCallback(() => {
    dispatch(confirmResultAction());
  }, [dispatch]);

  return {
    enabledButtons: {
      isButtonConfirmResultEnabled,
      isButtonChangeResultEnabled,
      isButtonCancelRoundEnabled,
      isButtonOpenRoundEnabled,
      isButtonStartDrawEnabled,
    },
    isChangeDealerEnabled,
    onOpenRoundClick,
    onStartDrawClick,
    onConfirmResultClick,
  };
};
