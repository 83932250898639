import { Icon } from 'components/atoms';
import fontService, { FontWeight } from 'services/FontService';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: linear-gradient(180deg, rgba(52, 52, 52, 0) 45.86%, #343434 100%);
`;

export const ResultValueBG = styled(Icon)`
  position: absolute;
  top: -34px;
  left: 0;
  height: 100%;
  width: 100%;
`;

export const ResultsWrap = styled.div`
  position: absolute;
  top: 164px;
  display: flex;
  gap: 56px;
`;

export const Result = styled.div`
  width: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

export const ResultValue = styled.div`
  width: 120px;
  height: 120px;
`;

export const ResultLabel = styled.p`
  ${fontService.getFont({ size: 32, lineHeight: 100, weight: FontWeight.bold })};
  ${({ theme: { text } }) => css`
    color: ${text.primaryDefault};
  `};
`;

export const TripleText = styled.p`
  position: relative;
  top: 92px;
  ${fontService.getFont({ size: 40, lineHeight: 100, weight: FontWeight.bold })};
  ${({ theme: { text } }) => css`
    color: ${text.primaryDefault};
  `};
  text-transform: uppercase;
`;
