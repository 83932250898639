import { ActionType, createAction, createReducer } from 'typesafe-actions';
import { tableMock } from 'mocks/tableMock';
import { ITable, IWinDataType, TableStates } from 'types/tables';
import { IChatMessage } from 'types/chat';
import { DealerType, IDataResponse, TableCommonType, ToastType } from 'types';
import { TablesClosingStatus } from 'constants/tables';
import { messagesQueue } from 'helpers';
import { getDrawResults, getWinners } from 'helpers/winDataHelpers';
import { ACTIONS } from '../constants';

export interface IGameState {
  gameData: ITable;
}

export const gameState: IGameState = {
  gameData: { ...tableMock },
};

export const saveGameDataToStore = createAction(ACTIONS.SAVE_GAME_TO_STORE)<{
  selectedTableData: TableCommonType;
}>();

export const changeDealerAction = createAction(ACTIONS.CHANGE_DEALER)<{ dealerId: string }>();
export const changeDealerFailureAction = createAction(
  ACTIONS.CHANGE_DEALER_FAILURE,
)<IDataResponse>();
export const changeDealerSuccessAction = createAction(ACTIONS.CHANGE_DEALER_SUCCESS)<{
  dealer: DealerType;
}>();

export const changeTimeLeftAction = createAction(ACTIONS.CHANGE_TIME_LEFT)<{
  bettingTimeLeft?: number;
  drawTimeLeft?: number;
}>();

export const decrementBettingTimeAction = createAction(ACTIONS.DECREMENT_BETTING_TIME)();

export const startDecrementBettingTimeAction = createAction(ACTIONS.START_DECREMENT_BETTING_TIME)();
export const stopDecrementBettingTimeAction = createAction(ACTIONS.STOP_DECREMENT_BETTING_TIME)();

export const incrementDrawTimeAction = createAction(ACTIONS.INCREMENT_DRAW_TIME)();

export const startIncrementDrawTimeAction = createAction(ACTIONS.START_INCREMENT_DRAW_TIME)();
export const stopIncrementDrawTimeAction = createAction(ACTIONS.STOP_INCREMENT_DRAW_TIME)();

export const startRoundAction = createAction(ACTIONS.START_ROUND)();
export const startRoundSuccessAction = createAction(ACTIONS.START_ROUND_SUCCESS)();
export const cancelRoundAction = createAction(ACTIONS.CANCEL_ROUND)<{ shiftManagerId: string }>();
export const cancelRoundFailureAction = createAction(ACTIONS.CANCEL_ROUND_FAILURE)<IDataResponse>();
export const roundCanceledAction = createAction(ACTIONS.CANCEL_ROUND_SUCCESS)();

export const saveGameIdToStore = createAction(ACTIONS.SAVE_GAME_ID_TO_STORE)<{ gameId: string }>();
export const saveRoundsResultsToStore = createAction(ACTIONS.SAVE_ROUNDS_RESULTS_TO_STORE)<{
  drawResults: string[];
}>();
export const changeGameStatusAction = createAction(ACTIONS.CHANGE_GAME_STATUS)<{
  status: string;
}>();
export const changeGameStateAction = createAction(ACTIONS.CHANGE_GAME_STATE)<{
  tableState: TableStates;
}>();
export const bettingTimeStartedAction = createAction(ACTIONS.BETTING_TIME_STARTED)<{
  bettingTime: number;
  startedAt: string;
}>();

export const drawTimeStartedAction = createAction(ACTIONS.DRAW_TIME_STARTED)<{
  drawStartedAt: string;
}>();
export const bettingTimeFinishedAction = createAction(ACTIONS.BETTING_TIME_FINISHED)();

export const startDrawAction = createAction(ACTIONS.START_DRAW)();
export const startDrawSuccessAction = createAction(ACTIONS.START_DRAW_SUCCESS)();

export const saveRoundDataToStore = createAction(ACTIONS.SAVE_ROUND_DATA_TO_STORE)<{
  winData: IWinDataType;
  drawTime: number;
}>();

export const drawFinishedSuccessAction = createAction(ACTIONS.DRAW_FINISHED_SUCCESS)();

export const confirmResultAction = createAction(ACTIONS.CONFIRM_RESULT)();
export const confirmResultSuccessAction = createAction(ACTIONS.CONFIRM_RESULT_SUCCESS)<{
  winData: IWinDataType;
}>();

export const roundFinishedSuccessAction = createAction(ACTIONS.ROUND_FINISHED_SUCCESS)();

export const tableCloseVerifyAction = createAction(ACTIONS.CLOSE_TABLE_VERIFY)<{
  shiftManagerId: string;
}>();
export const tableCloseVerifyFailureAction = createAction(
  ACTIONS.CLOSE_TABLE_VERIFY_FAILURE,
)<IDataResponse>();
export const tableCloseVerifySuccessAction = createAction(ACTIONS.CLOSE_TABLE_VERIFY_SUCCESS)();

export const tableCloseAction = createAction(ACTIONS.CLOSE_TABLE)<{
  shiftManagerId: string;
  closingStatus: TablesClosingStatus;
}>();

export const tableCloseSuccessAction = createAction(ACTIONS.CLOSE_TABLE_SUCCESS)();

export const tableCloseNotificationAction = createAction(ACTIONS.CLOSE_TABLE_NOTIFICATION)();

export const changeResultVerifyAction = createAction(ACTIONS.CHANGE_RESULT_VERIFY)<{
  shiftManagerId: string;
}>();
export const changeResultVerifyFailureAction = createAction(
  ACTIONS.CHANGE_RESULT_VERIFY_FAILURE,
)<IDataResponse>();
export const changeResultVerifySuccessAction = createAction(ACTIONS.CHANGE_RESULT_VERIFY_SUCCESS)();
export const changeResultAction = createAction(ACTIONS.CHANGE_RESULT)<{
  shiftManagerId: string;
  result: any;
}>();
export const changeResultFailureAction = createAction(
  ACTIONS.CHANGE_RESULT_FAILURE,
)<IDataResponse>();
export const changeResultSuccessAction = createAction(ACTIONS.CHANGE_RESULT_SUCCESS)();

export const clearGameDataAction = createAction(ACTIONS.CLEAR_GAME_DATA)();

export const addToastAction = createAction(ACTIONS.ADD_TOAST)<ToastType>();
export const removeToastAction = createAction(ACTIONS.REMOVE_TOAST)();

export const saveChatMessagesAction = createAction(ACTIONS.SAVE_CHAT_MESSAGES)<IChatMessage[]>();

export const saveNewChatMessageAction = createAction(ACTIONS.SAVE_NEW_CHAT_MESSAGE)<IChatMessage>();

const actions = {
  saveGameDataToStore,
  saveGameIdToStore,
  changeDealerSuccessAction,
  startRoundSuccessAction,
  roundCanceledAction,
  changeGameStatusAction,
  startDrawSuccessAction,
  drawFinishedSuccessAction,
  saveRoundDataToStore,
  confirmResultSuccessAction,
  roundFinishedSuccessAction,
  bettingTimeStartedAction,
  drawTimeStartedAction,
  changeGameStateAction,
  tableCloseVerifyAction,
  tableCloseVerifySuccessAction,
  tableCloseAction,
  changeResultVerifyAction,
  changeResultVerifySuccessAction,
  changeResultAction,
  changeResultSuccessAction,
  clearGameDataAction,
  saveRoundsResultsToStore,
  tableCloseNotificationAction,
  changeTimeLeftAction,
  decrementBettingTimeAction,
  startDecrementBettingTimeAction,
  stopDecrementBettingTimeAction,
  incrementDrawTimeAction,
  startIncrementDrawTimeAction,
  stopIncrementDrawTimeAction,
  addToastAction,
  removeToastAction,
  saveChatMessagesAction,
  saveNewChatMessageAction,
};

export const gameReducer = createReducer<IGameState, ActionType<typeof actions>>(gameState)
  .handleAction(saveGameDataToStore, (state, { payload: { selectedTableData } }) => ({
    ...state,
    gameData: {
      ...state.gameData,
      table_id: selectedTableData.table_id,
      auto_confirm_result: selectedTableData.auto_confirm_result,
      auto_draw: selectedTableData.auto_draw,
      auto_round: selectedTableData.auto_round,
      betting_time: selectedTableData.betting_time,
      stream_low: selectedTableData.stream_low,
      game_type: selectedTableData.game_type,
    },
  }))
  .handleAction(saveRoundsResultsToStore, (state, { payload: { drawResults } }) => ({
    ...state,
    gameData: {
      ...state.gameData,
      round: {
        ...state.gameData.round,
        drawResults: [...drawResults],
      },
    },
  }))
  .handleAction(saveGameIdToStore, (state, { payload: { gameId } }) => ({
    ...state,
    gameData: { ...state.gameData, gameId },
  }))
  .handleAction(changeDealerSuccessAction, (state, { payload: { dealer } }) => ({
    ...state,
    gameData: { ...state.gameData, dealer },
  }))
  .handleAction(bettingTimeStartedAction, (state, { payload: { bettingTime, startedAt } }) => ({
    ...state,
    gameData: {
      ...state.gameData,
      betting_time: bettingTime,
      round: { ...state.gameData.round, startedAt },
    },
  }))
  .handleAction(drawTimeStartedAction, (state, { payload: { drawStartedAt } }) => ({
    ...state,
    gameData: {
      ...state.gameData,
      round: { ...state.gameData.round, drawStartedAt },
    },
  }))
  .handleAction(changeGameStateAction, (state, { payload: { tableState } }) => ({
    ...state,
    gameData: { ...state.gameData, tableState },
  }))

  .handleAction(changeTimeLeftAction, (state, { payload: { bettingTimeLeft, drawTimeLeft } }) => ({
    ...state,
    gameData: {
      ...state.gameData,
      round: {
        ...state.gameData.round,
        timerLeft: {
          bettingTimeLeft,
          drawTimeLeft,
        },
      },
    },
  }))
  .handleAction(decrementBettingTimeAction, (state) => {
    const bettingTimeLeft = state.gameData.round.timerLeft?.bettingTimeLeft;

    return {
      ...state,
      gameData: {
        ...state.gameData,
        round: {
          ...state.gameData.round,
          timerLeft: {
            ...state.gameData.round.timerLeft,
            bettingTimeLeft: bettingTimeLeft !== undefined ? bettingTimeLeft - 1 : undefined,
          },
        },
      },
    };
  })
  .handleAction(incrementDrawTimeAction, (state) => {
    const drawTimeLeft = state.gameData.round.timerLeft?.drawTimeLeft;

    return {
      ...state,
      gameData: {
        ...state.gameData,
        round: {
          ...state.gameData.round,
          timerLeft: {
            ...state.gameData.round.timerLeft,
            drawTimeLeft: drawTimeLeft !== undefined ? drawTimeLeft + 1 : undefined,
          },
        },
      },
    };
  })
  .handleAction(roundCanceledAction, (state) => ({
    ...state,
    gameData: {
      ...state.gameData,
      round: {
        drawResults: [...state.gameData.round.drawResults],
        ballWinner: undefined,
        diceWinners: undefined,
        isFinished: false,
      },
    },
  }))
  .handleAction(startRoundSuccessAction, (state) => ({
    ...state,
    gameData: { ...state.gameData, tableState: TableStates.RoundStarted },
  }))
  .handleAction(startDrawSuccessAction, (state) => ({
    ...state,
    gameData: { ...state.gameData, tableState: TableStates.DrawStarted },
  }))

  .handleAction(drawFinishedSuccessAction, (state) => ({
    ...state,
    gameData: { ...state.gameData, tableState: TableStates.DrawFinished },
  }))
  .handleAction(changeGameStatusAction, (state, { payload: { status } }) => ({
    ...state,
    gameData: { ...state.gameData, statusText: status },
  }))
  .handleAction(saveRoundDataToStore, (state, { payload: { winData, drawTime } }) => ({
    ...state,
    gameData: {
      ...state.gameData,
      round: {
        ...state.gameData.round,
        isFinished: true,
        timeValue: drawTime,
        ...getWinners(winData),
      },
    },
  }))
  .handleAction(confirmResultSuccessAction, (state, { payload: { winData } }) => ({
    ...state,
    gameData: {
      ...state.gameData,
      round: {
        ...state.gameData.round,
        drawResults: getDrawResults(winData, state.gameData.round.drawResults),
        timeValue: undefined,
        ...getWinners(winData),
      },
    },
  }))
  .handleAction(roundFinishedSuccessAction, (state) => ({
    ...state,
    gameData: {
      ...state.gameData,

      round: {
        drawResults: [...state.gameData.round.drawResults],
        isFinished: false,
        ballWinner: undefined,
        diceWinners: undefined,
      },
      changeResult: undefined,
    },
  }))
  .handleAction(tableCloseVerifyAction, (state, { payload: { shiftManagerId } }) => ({
    ...state,
    gameData: {
      ...state.gameData,
      closed: {
        shiftManagerId,
      },
    },
  }))
  .handleAction(tableCloseVerifySuccessAction, (state) => ({
    ...state,
    gameData: {
      ...state.gameData,
      closed: {
        ...state.gameData.closed,
        verified: true,
      },
    },
  }))
  .handleAction(tableCloseAction, (state) => ({
    ...state,
    gameData: {
      ...state.gameData,
      closed: {
        ...state.gameData.closed,
        isInProgress: true,
      },
    },
  }))
  .handleAction(tableCloseNotificationAction, (state) => ({
    ...state,
    gameData: {
      ...state.gameData,
      closed: {
        ...state.gameData.closed,
        notification: true,
      },
    },
  }))
  .handleAction(changeResultVerifyAction, (state, { payload: { shiftManagerId } }) => ({
    ...state,
    gameData: {
      ...state.gameData,
      changeResult: {
        shiftManagerId,
      },
    },
  }))
  .handleAction(changeResultVerifySuccessAction, (state) => ({
    ...state,
    gameData: {
      ...state.gameData,
      changeResult: {
        ...state.gameData.changeResult,
        verified: true,
      },
    },
  }))
  .handleAction(changeResultAction, (state) => ({
    ...state,
    gameData: {
      ...state.gameData,
      changeResult: {
        ...state.gameData.changeResult,
        isInProgress: true,
      },
    },
  }))
  .handleAction(changeResultSuccessAction, (state) => ({
    ...state,
    gameData: {
      ...state.gameData,
      changeResult: {
        ...state.gameData.changeResult,
        isChanged: true,
      },
    },
  }))
  .handleAction(addToastAction, (state, { payload }) => ({
    ...state,
    gameData: {
      ...state.gameData,
      toastItem: payload,
    },
  }))
  .handleAction(removeToastAction, (state) => ({
    ...state,
    gameData: {
      ...state.gameData,
      toastItem: null,
    },
  }))
  .handleAction(saveChatMessagesAction, (state, { payload }) => ({
    ...state,
    gameData: {
      ...state.gameData,
      chatMessages: payload,
    },
  }))
  .handleAction(saveNewChatMessageAction, (state, { payload }) => ({
    ...state,
    gameData: {
      ...state.gameData,
      chatMessages: messagesQueue(state.gameData.chatMessages, payload),
    },
  }))
  .handleAction(clearGameDataAction, (state) => ({
    ...state,
    gameData: { ...tableMock },
  }));
