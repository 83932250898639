import React from 'react';
import { ResultDice } from 'types';
import { DiceColored } from './styles';

export enum DiceSize {
  Small = 'Small',
}

export interface IProps {
  value: ResultDice;
  size?: DiceSize;
}

export const Dice = ({ value, size }: IProps) => <DiceColored value={value} size={size} />;
