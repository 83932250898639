import React, { memo } from 'react';

import { ISelectOption } from 'types';
import styled from 'styled-components';
import fontService, { FontWeight } from 'services/FontService';
import { Icon, IconNames, Select } from 'atoms';
import { tableStatusOptions } from 'constants/tables';

export interface IProps {
  tableGameOptions: ISelectOption<string>[];
  searchValue: string;
  sortGameValue: ISelectOption<string>;
  sortStatusValue: ISelectOption<string>;
  onSearchChange: (value: string) => void;
  onSortGameClick: (value: ISelectOption<string>) => void;
  onSortStatusClick: (value: ISelectOption<string>) => void;
}

export const TablesFilter = memo(
  ({
    tableGameOptions,
    searchValue,
    sortGameValue,
    sortStatusValue,
    onSortStatusClick,
    onSortGameClick,
    onSearchChange,
  }: IProps) => (
    <Container>
      <SearchContainer>
        <FilterName>search</FilterName>
        <InputWrapper>
          <SearchInput
            value={searchValue}
            placeholder="Enter table ID"
            onChange={({ target }) => onSearchChange(target.value)}
          />
          <IconSearch name={IconNames.Search} />
        </InputWrapper>
      </SearchContainer>
      <SortTablesContainer>
        <SortGame>
          <FilterName>sort game:</FilterName>
          <GameSelectWrapper>
            <Select
              preselectedOption={sortGameValue}
              options={tableGameOptions}
              placeholder="Select game"
              disabled={false}
              onSelect={onSortGameClick}
            />
          </GameSelectWrapper>
        </SortGame>
        <Separator />
        <SortStatus>
          <FilterName>sort table status:</FilterName>
          <StatusSelectWrapper>
            <Select
              preselectedOption={sortStatusValue}
              options={tableStatusOptions}
              placeholder="Select status"
              disabled={false}
              onSelect={onSortStatusClick}
            />
          </StatusSelectWrapper>
        </SortStatus>
      </SortTablesContainer>
    </Container>
  ),
);

const Container = styled.div`
  width: 100%;
  max-width: 1152px;
  display: flex;
  justify-content: space-between;
  margin: 24px auto;
  gap: 24px;
  flex-wrap: wrap;
`;

const SearchContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  width: max-content;
`;

const InputWrapper = styled.div`
  position: relative;
`;

const SearchInput = styled.input`
  width: 264px;
  padding: 8px 24px 8px 0;
  box-sizing: border-box;
  background-color: transparent !important;
  border-style: solid;
  border-width: 0 0 1px 0;
  border-color: ${({ theme: { borderColor } }) => borderColor};
  color: ${({ theme: { primaryTextColor } }) => primaryTextColor};
  ${fontService.getFont({ size: 12, lineHeight: 133 })};
  cursor: pointer;
  outline: none;
  &:focus {
    border-color: ${({ theme: { focusedBorderColor } }) => focusedBorderColor};
  }

  &::placeholder,
  &:-ms-input-placeholder,
  &::-ms-input-placeholder {
    letter-spacing: 0.1em;
    color: ${({ theme: { tertiaryTextColor } }) => tertiaryTextColor};
  }
`;

const IconSearch = styled(Icon)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 8px;
  width: 16px;
  height: 16px;
`;

const FilterName = styled.p`
  ${fontService.text({ size: 12, lineHeight: 133, weight: FontWeight.medium })};
  color: ${({ theme: { tertiaryTextColor } }) => tertiaryTextColor};
  text-transform: uppercase;
`;

const SortTablesContainer = styled.div`
  display: flex;
  gap: 40px;
  align-items: center;
  flex-wrap: wrap;
`;

const SortGame = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const GameSelectWrapper = styled.div`
  min-width: 90px;
`;

const StatusSelectWrapper = styled.div`
  min-width: 110px;
`;

const SortStatus = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const Separator = styled.div`
  width: 2px;
  height: 10px;
  background: #fff7eb3d;
  opacity: 0.4;
`;
