import React, { memo } from 'react';

import { ISelectOption } from 'types';
import { IconNames } from 'atoms';

import { Dropdown, DropdownItem, SelectedOption, SelectInput, StyledIcon, Wrapper } from './styles';

export interface ISelect {
  options: ISelectOption<string>[];
  selectedOption: ISelectOption<string>;
  placeholder?: string;
  disabled?: boolean;
  isOpened: boolean;
  onOptionClick: (option: ISelectOption<string>) => void;
  onSelectClick: () => void;
  dropdownRef: React.Ref<HTMLDivElement>;
  className?: string;
}

export const SelectComponent = memo(
  ({
    className,
    options,
    selectedOption,
    placeholder,
    disabled,
    isOpened,
    onSelectClick,
    onOptionClick,
    dropdownRef,
  }: ISelect) => (
    <Wrapper ref={dropdownRef} className={className}>
      <SelectInput disabled={disabled} onClick={onSelectClick} isOpened={isOpened}>
        <SelectedOption selected={Boolean(selectedOption.value.length)}>
          {selectedOption.value.length ? selectedOption.label : placeholder}
        </SelectedOption>
        <StyledIcon name={IconNames.Triangle} />
      </SelectInput>

      <Dropdown isOpened={isOpened} disabled={disabled}>
        {options.map((option) => (
          <DropdownItem
            onClick={() => onOptionClick(option)}
            key={option.value}
            selected={option.value === selectedOption.value}>
            {option.label}
          </DropdownItem>
        ))}
      </Dropdown>
    </Wrapper>
  ),
);
