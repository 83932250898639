import { put } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';

import {
  saveRoundDataToStore,
  changeGameStatusAction,
  drawFinishedSuccessAction,
  changeGameStateAction,
} from 'data/reducers';
import { DrawFinishedResponse, Notifications, TableStates } from 'types';
import { gameStatus } from 'constants/common';

export function* drawFinished({ type: notificationType, data: result }: DrawFinishedResponse) {
  try {
    if (notificationType === Notifications.DRAW_FINISHED) {
      const { drawTime } = result;
      yield put(saveRoundDataToStore({ winData: result.winner || result.winners || '', drawTime }));
      yield put(changeGameStatusAction({ status: `${gameStatus.ConfirmResult} ${drawTime} sec` }));
      yield put(changeGameStateAction({ tableState: TableStates.DrawFinished }));
      yield put(drawFinishedSuccessAction());
    }
  } catch (error) {
    Sentry.captureException(error);
  }
}
