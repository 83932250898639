import React, { memo, useCallback, useEffect, useState } from 'react';

import { ISelectOption } from 'types';
import { useOutsideClick } from 'hooks';
import { SelectComponent } from './Select';

interface ISelectComponent {
  preselectedOption?: ISelectOption<string>;
  options: ISelectOption<string>[];
  placeholder: string;
  disabled: boolean;
  onSelect: (option: ISelectOption<string>) => void;
  className?: string;
}

export const Select = memo(
  ({
    preselectedOption,
    options,
    placeholder,
    disabled,
    onSelect,
    className,
  }: ISelectComponent) => {
    const [isOpened, setIsOpened] = useState<boolean>(false);
    const [selectedOption, setSelectedOption] = useState<ISelectOption<string>>({
      label: '',
      value: '',
    });

    useEffect(() => {
      if (preselectedOption) {
        setSelectedOption(preselectedOption);
      }
    }, [preselectedOption]);

    const handleClickOutside = useCallback(() => setIsOpened(false), []);

    const ref = useOutsideClick(handleClickOutside);

    const onSelectClick = useCallback(() => setIsOpened((prevState) => !prevState), []);

    const onOptionClick = useCallback(
      (option: ISelectOption<string>) => {
        setSelectedOption({ label: option.label, value: option.value });
        onSelect(option);
      },
      [onSelect],
    );

    return (
      <SelectComponent
        options={options}
        placeholder={placeholder}
        selectedOption={selectedOption}
        isOpened={isOpened}
        onOptionClick={onOptionClick}
        onSelectClick={onSelectClick}
        dropdownRef={ref}
        disabled={disabled}
        className={className}
      />
    );
  },
);
