export enum TablesStatus {
  Open = 'open',
  Inactive = 'Inactive',
  Maintenance = 'Maintenance',
  Taken = 'taken',
}

export enum TablesClosingStatus {
  Inactive = 'Inactive',
  Maintenance = 'Maintenance',
}

export const tableGameOptions = [{ value: 'all', label: 'All games' }];

export const tableStatusOptions = [
  { value: 'all', label: 'All statuses' },
  { value: 'Active', label: 'Active' },
  { value: 'Maintenance', label: 'Maintenance' },
  { value: 'Inactive', label: 'Inactive' },
];
