import { IWinDataType, IRoundWinners } from 'types/tables';
import { queue } from './arrayHelpers';

export const getWinners = (winData: IWinDataType): IRoundWinners => {
  if (typeof winData === 'string' && winData.length !== 0) {
    return { ballWinner: winData };
  }
  if (typeof winData === 'object' && winData !== null && 'firstDice' in winData) {
    return { diceWinners: winData };
  }
  return {};
};

export const getDrawResults = (winData: IWinDataType, previousData: string[]): string[] => {
  if (typeof winData === 'string' && winData.length !== 0) {
    return queue(previousData, winData);
  }
  if (typeof winData === 'object' && winData !== null && 'firstDice' in winData) {
    return [winData.firstDice, winData.secondDice, winData.thirdDice];
  }
  return [];
};
