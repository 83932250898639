import { delay, put } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';
import { isEmpty } from 'ramda';

import {
  connectToWebSocketSuccessAction,
  changeDealerSuccessAction,
  saveGameIdToStore,
  saveGameDataToStore,
  changeGameStateAction,
  changeGameStatusAction,
  saveRoundDataToStore,
  saveRoundsResultsToStore,
  openTableSuccessAction,
  confirmResultSuccessAction,
  bettingTimeStartedAction,
  drawTimeStartedAction,
  changeTimeLeftAction,
  startDecrementTimeLeftAction,
  startIncrementTimeLeftAction,
  drawFinishedAction,
  resultConfirmedAction,
} from 'data/reducers';
import { getDrawTimeLeft, getTimeLeft } from 'helpers';
import { DealerJoinedResponse, Notifications, TableStates, GameType } from 'types';
import { gameStatus } from 'constants/common';
import { setJackpot, setJackpotWon, setStageJackpot } from 'data/reducers/AuthReducer';
import { ACTIONS } from 'data/constants';
import { PositionJackpot } from 'types/authorization';
import { TimerTypes } from 'types/tables';

export function* dealerJoined({ type: responseType, data }: DealerJoinedResponse) {
  try {
    if (responseType === Notifications.DEALER_CONNECTED) {
      yield put(connectToWebSocketSuccessAction());

      if (data.state && !isEmpty(data.state) && data.state.table) {
        const state = data.state.status;
        yield put(openTableSuccessAction());
        yield put(changeDealerSuccessAction({ dealer: data.state?.dealer }));
        yield put(saveGameIdToStore({ gameId: data.state?.round?.gameId || '-' }));
        yield put(saveGameDataToStore({ selectedTableData: data.state.table }));
        yield put(changeGameStateAction({ tableState: state }));

        if (data.state.table.game_type === GameType.BAC && data.state.last_balls) {
          yield put(saveRoundsResultsToStore({ drawResults: data.state.last_balls }));
        }
        if (data.state.table.game_type === GameType.HHH && data.state.lastDices?.length) {
          const lastResult: any = data.state.lastDices[data.state.lastDices.length - 1];
          yield put(
            saveRoundsResultsToStore({
              drawResults: [lastResult.firstDice, lastResult.secondDice, lastResult.thirdDice],
            }),
          );
        }

        if (data.state.jackpots?.length) {
          const currentJackpot = data.state.jackpots?.find(
            (jackpot) => jackpot.position === PositionJackpot.CURRENT,
          );

          if (currentJackpot) {
            yield put(setJackpot(currentJackpot));

            if (currentJackpot?.draw?.stageNumber) {
              yield put(
                setStageJackpot({
                  players: currentJackpot.draw.players?.length ? currentJackpot.draw.players : [],
                  stageNumber: currentJackpot.draw.stageNumber,
                }),
              );
            }
          }
        }

        switch (state) {
          case TableStates.TableOpened:
            yield put(changeGameStatusAction({ status: gameStatus.OpenRound }));
            break;
          case TableStates.BettingTimeStarted:
            if (data.state.round?.finishBettingTime) {
              yield put(
                changeTimeLeftAction({
                  timerLeft: getTimeLeft(
                    data.state.round.finishBettingTime,
                    data.state.table.betting_time,
                  ),
                }),
              );
              yield put(
                bettingTimeStartedAction({
                  bettingTime: data.state.table.betting_time,
                  finishBettingTime: data.state.round.finishBettingTime,
                }),
              );
              yield put(startDecrementTimeLeftAction({ type: TimerTypes.bettingTime }));
            }
            yield put(changeGameStatusAction({ status: gameStatus.BettingTime }));
            break;
          case TableStates.BettingTimeFinished:
            yield put(changeGameStatusAction({ status: gameStatus.StartDraw }));
            break;
          case TableStates.DrawStarted:
            if (data.state.round?.drawStartedAt) {
              yield put(
                changeTimeLeftAction({
                  timerLeft: getDrawTimeLeft(data.state.round.drawStartedAt),
                }),
              );
              yield put(drawTimeStartedAction({ drawStartedAt: data.state.round.drawStartedAt }));
            }
            yield put(startIncrementTimeLeftAction());
            yield put(changeGameStatusAction({ status: gameStatus.StartedDraw }));
            break;
          case TableStates.DrawFinished:
            yield put(
              changeGameStatusAction({
                status: gameStatus.ConfirmResult,
              }),
            );
            yield put(
              saveRoundDataToStore({
                winData: data?.state?.round?.winner || data?.state?.round?.winners || '',
                drawTime: data.state?.round?.drawTime || 0,
              }),
            );
            if (data.state?.round?.finishDrawnDisplay) {
              yield put(
                changeTimeLeftAction({
                  timerLeft: getTimeLeft(
                    data.state.round.finishDrawnDisplay,
                    data.state.table.displaying_drawn_result,
                  ),
                }),
              );
              yield put(
                drawFinishedAction({
                  displayingDrawTime: data.state.table.displaying_drawn_result,
                  finishDrawnDisplay: data.state.round.finishDrawnDisplay,
                }),
              );
              yield put(startDecrementTimeLeftAction({ type: TimerTypes.displayingDrawn }));
            }
            break;
          case TableStates.ResultConfirmed:
            yield put(changeGameStatusAction({ status: gameStatus.DisplayingWin }));
            yield put(
              confirmResultSuccessAction({
                winData: data?.state?.round?.winner || data?.state?.round?.winners || '',
              }),
            );
            if (data.state?.round?.finishResultDisplay) {
              yield put(
                changeTimeLeftAction({
                  timerLeft: getTimeLeft(
                    data.state?.round?.finishResultDisplay,
                    data.state.table.displaying_win_result,
                  ),
                }),
              );
              yield put(
                resultConfirmedAction({
                  displayingWinTime: data.state.table.displaying_win_result,
                  finishWinDisplay: data.state.round.finishResultDisplay,
                }),
              );
              yield put(startDecrementTimeLeftAction({ type: TimerTypes.displayingWin }));
            }
            break;
          case TableStates.RoundFinished:
            yield put(setJackpotWon({ amount: null, players: null, currency: null }));
            yield put(setStageJackpot(null));

            yield put(changeGameStatusAction({ status: gameStatus.OpenRound }));
            break;
          case TableStates.RoundCanceled:
            yield put(changeGameStatusAction({ status: gameStatus.OpenRound }));
            break;
          default:
        }
      }
    }
  } catch (error) {
    Sentry.captureException(error);
  } finally {
    yield delay(3000);

    yield put({ type: ACTIONS.WATCH_DATE_CONTROL_INTERVAL });
  }
}
