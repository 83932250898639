import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getTables } from 'core/selectors';
import {
  clearGameDataAction,
  clearSagaChannels,
  clearSelectedTableToStore,
  closeTableAction,
  disconnectFromWebSocketAction,
  getTablesRequest,
} from 'data/reducers';
import { useRequestLoading } from 'hooks/useRequestLoading';
import { tableGameOptions, tableStatusOptions } from 'constants/tables';
import { ISelectOption } from 'types';

import { Tables } from './Tables';

export const Container = () => {
  const dispatch = useDispatch();
  const tablesData = useSelector(getTables);
  const loadingTables = useRequestLoading(getTablesRequest);

  const [gameTypeOptions, setGameTypeOptions] = useState<ISelectOption<string>[]>(tableGameOptions);

  const [searchValue, setSearchValue] = useState<string>('');
  const [sortGameValue, setSortGameValue] = useState<ISelectOption<string>>(tableGameOptions[0]);
  const [sortStatusValue, setSortStatusValue] = useState<ISelectOption<string>>(
    tableStatusOptions[0],
  );
  const [filteredTablesData, setFilteredTablesData] = useState([]);

  const onSearchChange = useCallback((value: string) => setSearchValue(value), []);

  const onSortGameClick = useCallback((option: ISelectOption<string>) => {
    setSortGameValue(option);
  }, []);

  const onSortStatusClick = useCallback((option: ISelectOption<string>) => {
    setSortStatusValue(option);
  }, []);

  useEffect(() => {
    const filteredData = tablesData.filter(({ table_id, game_type, status }: any) => {
      const matchesSearch =
        !searchValue || table_id.toLowerCase().includes(searchValue.toLowerCase());
      const matchesGameType = sortGameValue.value === 'all' || game_type === sortGameValue.value;
      const matchesStatus =
        sortStatusValue.value === 'all' ||
        (sortStatusValue.value === 'Active' && status === 'open') ||
        (sortStatusValue.value === 'Active' && status === 'taken') ||
        status === sortStatusValue.value;
      return matchesSearch && matchesGameType && matchesStatus;
    });
    setFilteredTablesData(filteredData);
  }, [searchValue, tablesData, sortGameValue, sortStatusValue]);

  useEffect(() => {
    const gameTypes = Array.from(new Set(tablesData.map(({ game_type }: any) => game_type)))
      .filter((type): type is string => Boolean(type))
      .map((type) => ({ value: type, label: type.toUpperCase() }));

    setGameTypeOptions([...tableGameOptions, ...gameTypes]);
  }, [tablesData]);

  useEffect(() => {
    dispatch(getTablesRequest());
  }, [dispatch]);

  useEffect(() => {
    dispatch(clearGameDataAction());
    dispatch(clearSelectedTableToStore());
    dispatch(disconnectFromWebSocketAction());
    dispatch(clearSagaChannels());
    dispatch(closeTableAction());
  }, [dispatch]);

  return (
    <Tables
      tablesData={filteredTablesData}
      loading={loadingTables}
      tableGameOptions={gameTypeOptions}
      searchValue={searchValue}
      sortGameValue={sortGameValue}
      sortStatusValue={sortStatusValue}
      onSortStatusClick={onSortStatusClick}
      onSortGameClick={onSortGameClick}
      onSearchChange={onSearchChange}
    />
  );
};
