import { diceStyles } from 'constants/dices';
import styled, { css } from 'styled-components';
import { ResultDice } from 'types';
import { DiceSize } from './Dice';

export const DiceColored = styled.div<{ value: ResultDice; size?: DiceSize }>`
  height: 100%;
  width: 100%;
  ${({ value }) => diceStyles.default[value]};
  ${({ size, value }) => {
    switch (size) {
      case DiceSize.Small:
        return css`
          border-radius: 4px;
          box-shadow: ${value !== ResultDice.UNDEFINED && '0px -1px 4px 0px #fff7eb33'};
        `;
      default:
        return css`
          border-radius: 8px;
          box-shadow: ${value !== ResultDice.UNDEFINED && '0px -4px 8px 0px #fff7eb33'};
          border-width: 3px;
        `;
    }
  }}
`;
