import { put, select } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';

import {
  confirmResultSuccessAction,
  changeGameStateAction,
  changeGameStatusAction,
  changeTimeLeftAction,
  startDecrementTimeLeftAction,
  stopDecrementTimeLeftAction,
  resultConfirmedAction,
} from 'data/reducers';
import { Notifications, ResultConfirmedResponse, TableStates } from 'types';
import { gameStatus } from 'constants/common';
import { getGameData } from 'core/selectors';
import { TimerTypes } from 'types/tables';

export function* resultConfirmed({
  data: result,
  type: notificationType,
}: ResultConfirmedResponse) {
  try {
    if (notificationType === Notifications.RESULT_CONFIRMED) {
      const { displaying_win_result } = yield select(getGameData);

      yield put(confirmResultSuccessAction({ winData: result.winner || result.winners || '' }));
      yield put(changeGameStateAction({ tableState: TableStates.ResultConfirmed }));
      yield put(changeGameStatusAction({ status: gameStatus.DisplayingWin }));
      yield put(stopDecrementTimeLeftAction());
      yield put(
        resultConfirmedAction({
          displayingWinTime: displaying_win_result,
          finishWinDisplay: result.finishResultDisplay,
        }),
      );
      yield put(changeTimeLeftAction({ timerLeft: displaying_win_result }));
      yield put(startDecrementTimeLeftAction({ type: TimerTypes.displayingWin }));
    }
  } catch (error) {
    Sentry.captureException(error);
  }
}
