import { put, select } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';

import {
  saveRoundDataToStore,
  changeGameStatusAction,
  drawFinishedSuccessAction,
  changeGameStateAction,
  changeTimeLeftAction,
  stopIncrementTimeLeftAction,
  startDecrementTimeLeftAction,
  drawFinishedAction,
} from 'data/reducers';
import { DrawFinishedResponse, Notifications, TableStates } from 'types';
import { gameStatus } from 'constants/common';
import { getGameData } from 'core/selectors';
import { TimerTypes } from 'types/tables';

export function* drawFinished({ type: notificationType, data: result }: DrawFinishedResponse) {
  try {
    if (notificationType === Notifications.DRAW_FINISHED) {
      const { drawTime, finishDrawnDisplay } = result;
      const { displaying_drawn_result, auto_confirm_result } = yield select(getGameData);

      yield put(saveRoundDataToStore({ winData: result.winner || result.winners || '', drawTime }));
      yield put(changeGameStatusAction({ status: gameStatus.ConfirmResult }));
      yield put(changeGameStateAction({ tableState: TableStates.DrawFinished }));
      yield put(stopIncrementTimeLeftAction());
      if (auto_confirm_result) {
        yield put(
          drawFinishedAction({ displayingDrawTime: displaying_drawn_result, finishDrawnDisplay }),
        );
        yield put(changeTimeLeftAction({ timerLeft: displaying_drawn_result }));
        yield put(startDecrementTimeLeftAction({ type: TimerTypes.displayingDrawn }));
      }
      yield put(drawFinishedSuccessAction());
    }
  } catch (error) {
    Sentry.captureException(error);
  }
}
