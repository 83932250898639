import React, { useCallback, useState } from 'react';
import { SelectOptionType, ICustomError, ResultDice } from 'types';
import { PopupChangeResultDicesComponent } from './PopupChangeResultDices';

interface IProps {
  options: SelectOptionType<ResultDice>[];
  loading: boolean;
  onSubmit: (value: { firstDice: string; secondDice: string; thirdDice: string }) => void;
  onClose: () => void;
  error?: ICustomError;
}

export const PopupChangeResultDices = ({ options, loading, onSubmit, onClose, error }: IProps) => {
  const [resultValues, setResultValue] = useState<ResultDice[]>([]);

  const onChange = useCallback(
    (value: ResultDice) => {
      if (resultValues.length < 3) {
        setResultValue((prevState) => [...prevState, value]);
      }
    },
    [resultValues],
  );

  const onClearClick = useCallback(() => {
    setResultValue([]);
  }, []);

  const onConfirmClick = useCallback(() => {
    if (resultValues.length === 3) {
      onSubmit({
        firstDice: resultValues[0],
        secondDice: resultValues[1],
        thirdDice: resultValues[2],
      });
    }
  }, [resultValues, onSubmit]);

  return (
    <PopupChangeResultDicesComponent
      options={options}
      values={resultValues}
      loading={loading}
      onChange={onChange}
      onConfirmClick={onConfirmClick}
      onClearClick={onClearClick}
      onClose={onClose}
      error={error}
    />
  );
};
