import React, { useMemo } from 'react';
import { GameType, ICustomError, ResultBall, ResultDice, SelectOptionType } from 'types';
import { errorMessages } from 'constants/common';
import { ButtonVariants, Dice, DiceSize } from 'atoms';
import { SelectResult } from 'molecules';

import {
  ButtonsWrapper,
  ClearButton,
  ConfirmButton,
  Result,
  ResultsWrapper,
  SelectWrapper,
  Subtitle,
} from './styles';
import { Popup } from '../Popup';
import { ErrorMessage } from '../PopupVerification/styles';
import { OptionLabel, OptionValue } from '../SelectResult/styles';

export interface IProps {
  options: SelectOptionType<ResultDice>[];
  values: ResultDice[];
  loading: boolean;
  onChange: (value: ResultDice) => void;
  onConfirmClick: () => void;
  onClearClick: () => void;
  onClose: () => void;
  error?: ICustomError;
}

export const PopupChangeResultDicesComponent = ({
  options,
  values,
  loading,
  onChange,
  onConfirmClick,
  onClearClick,
  onClose,
  error,
}: IProps) => {
  const results = useMemo(
    () => [...values, ...Array(3 - values.length).fill(ResultBall.UNDEFINED)].slice(0, 3),
    [values],
  );

  return (
    <Popup title="Change result" onClose={onClose} loading={loading}>
      <Subtitle>Result</Subtitle>
      <ResultsWrapper>
        {results.map((value, index) => (
          <Result key={index}>
            <OptionValue>
              <Dice value={value} size={DiceSize.Small} />
            </OptionValue>
            <OptionLabel>
              {value === ResultDice.UNDEFINED
                ? `Dice ${index + 1}`
                : options.find(({ value: optionValue }) => optionValue === value).label}
            </OptionLabel>
          </Result>
        ))}
      </ResultsWrapper>
      <Subtitle>Color dice</Subtitle>
      <SelectWrapper>
        <SelectResult
          options={options}
          values={values}
          onChange={onChange}
          gameType={GameType.HHH}
        />
      </SelectWrapper>
      {error?.status === 601 ? (
        <ErrorMessage>{errorMessages.NotAvailableAction}</ErrorMessage>
      ) : null}
      <ButtonsWrapper>
        <ConfirmButton
          type="submit"
          disabled={values.length < 3}
          loading={loading}
          onClick={onConfirmClick}>
          Confirm
        </ConfirmButton>
        <ClearButton
          type="reset"
          variant={ButtonVariants.Cancel}
          disabled={values.length === 0 || loading}
          onClick={onClearClick}>
          Clear
        </ClearButton>
      </ButtonsWrapper>
    </Popup>
  );
};
