import React, { memo } from 'react';
import styled, { css } from 'styled-components';

import { Button, Icon, IconNames } from 'atoms';
import { TablesStatus } from 'constants/tables';
import fontService, { FontWeight } from 'services/FontService';
import { ITableCommon } from 'types/tables';
import { ToastTypes } from 'types';
import { Alert, AlertAssignments } from '../Alert';
import { StatusLabel } from '../StatusLabel';

export interface IProps {
  tableData: ITableCommon;
  onOpenTable: () => void;
}

export const TableCardComponent = memo(({ tableData, onOpenTable }: IProps) => (
  <Container status={tableData.status}>
    <StatusLabelWrapper>
      {tableData.status === TablesStatus.Open && (
        <Alert variant={ToastTypes.WARNING} assignment={AlertAssignments.Icon} showIcon />
      )}

      <StatusLabel status={tableData.status} />
    </StatusLabelWrapper>

    <TextTitle>{tableData.game_type}</TextTitle>
    <TextId>{tableData.table_id}</TextId>
    {tableData.status === TablesStatus.Taken ? (
      <ButtonOpenStyled disabled type="button">
        Select table
      </ButtonOpenStyled>
    ) : (
      <ButtonStyled onClick={onOpenTable} type="button" status={tableData.status}>
        {tableData.status === TablesStatus.Open ? 'reopen table' : 'Select table'}{' '}
        <Icon name={IconNames.ArrowRight} />
      </ButtonStyled>
    )}
  </Container>
));

const Container = styled.div<{ status: TablesStatus }>`
  width: 370px;
  padding: 24px;
  border-radius: 12px;
  border: ${({ theme: { cardBorderColor } }) => `1px solid ${cardBorderColor}`};
  background: ${({ theme: { cardBGColor } }) => cardBGColor};
  &:hover {
    background: ${({ theme: { cardBGHoverColor } }) => cardBGHoverColor};
    filter: drop-shadow(0px 4px 10px rgba(187, 147, 112, 0.12));
    cursor: pointer;
  }
  ${({ status, theme: { button } }) => {
    if (status === TablesStatus.Open || status === TablesStatus.Taken) {
      return css`
        &:hover {
          border: 1px solid ${button.acceptBrightBorder};
        }
      `;
    }
    if (status === TablesStatus.Maintenance) {
      return css`
        &:hover {
          border: 1px solid ${button.infoBrightBorder};
        }
      `;
    }
    return css`
      &:hover {
        border: 1px solid ${button.primaryBrightBorder};
      }
    `;
  }};
`;

const TextId = styled.p`
  ${fontService.text({ size: 24, lineHeight: 125, weight: FontWeight.bold })};
  color: ${({ theme: { primaryTextColor } }) => primaryTextColor};
  text-transform: uppercase;
  margin-bottom: 24px;
`;

const TextTitle = styled.p`
  ${fontService.text({ size: 16, lineHeight: 125, weight: FontWeight.medium })};
  color: ${({ theme: { tertiaryTextColor } }) => tertiaryTextColor};
  text-transform: uppercase;
  margin-bottom: 6px;
`;

const StatusLabelWrapper = styled.div`
  display: flex;
  margin-bottom: 64px;
  gap: 8px;
`;

const ButtonOpenStyled = styled(Button)`
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  background: #3c8024;
  border: 1px solid rgba(67, 172, 29, 0.24);
  cursor: not-allowed;
  &:hover {
    background: #3c8024;
  }
  &:disabled {
    background: #343434;
    border: 1px solid #686868;
  }
`;

const ButtonStyled = styled(Button)<{ status: TablesStatus }>`
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  ${({ status }) => {
    if (status === TablesStatus.Open) {
      return css`
        background: #3c8024;
        border: 1px solid rgba(67, 172, 29, 0.24);
        &:hover {
          background: #43ac1d;
        }
      `;
    }
    if (status === TablesStatus.Maintenance) {
      return css`
        background: #127bad;
        border: 1px solid rgba(87, 181, 227, 0.24);
        &:hover {
          background: #57b5e3;
        }
      `;
    }
    return css`
      background: #bb9370;
      border: 1px solid rgba(187, 147, 112, 0.24);
      &:hover {
        background: #d4bb93;
      }
    `;
  }};
`;
