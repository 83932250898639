import { call, put, select } from 'redux-saga/effects';

import { GameType, Notifications } from 'types';

import { webSocketService } from 'services/WebSocketService';
import { changeResultAction, changeResultFailureAction } from 'data/reducers';
import { ActionType } from 'typesafe-actions';
import { responseType } from 'constants/common';
import { getGameData } from 'core/selectors';

export function* changeResultSaga({
  payload: { shiftManagerId, result },
}: ActionType<typeof changeResultAction>) {
  const { game_type } = yield select(getGameData);
  const newResult = game_type === GameType.BAC ? { winner: result } : { diceWinners: result };

  const { type, data } = yield call(() =>
    webSocketService.sendMessage({
      type: Notifications.CHANGE_RESULT,
      data: { shiftManagerId, ...newResult },
    }),
  );

  if (type === responseType.Error) {
    yield put(changeResultFailureAction(data));
  }
}
