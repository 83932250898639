import * as React from 'react';
import { memo } from 'react';

import styled from 'styled-components';

import { Label, LabelVariants } from 'atoms';

export interface IConnectionStatusLabelComponent {
  variant?: LabelVariants;
  statusText: string;
}

export const ConnectionStatusLabelComponent = memo(
  ({ variant, statusText }: IConnectionStatusLabelComponent) => (
    <StyledLabel variant={variant}>{statusText}</StyledLabel>
  ),
);

const StyledLabel = styled(Label)`
  font-size: 12px;
  line-height: 16px;
  border-radius: 4px;
  padding: 6px 16px;
`;
