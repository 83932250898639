import React, { useMemo } from 'react';
import { Ball, Dice, DiceSize } from 'components/atoms';
import { GameType, SelectOptionType } from 'types';
import { OptionLabel, OptionValue, OptionWrapper, Wrapper } from './styles';

export interface IProps<T> {
  options: SelectOptionType<T>[];
  values?: T[];
  onChange: (value: T) => void;
  gameType: GameType;
}

export const SelectResult = <ValueType extends {}>({
  options,
  values,
  onChange,
  gameType,
}: IProps<ValueType>) => {
  const items = useMemo(
    () =>
      options.map((option) => (
        <OptionWrapper
          key={option.value}
          isActive={values?.includes(option.value)}
          onClick={() => onChange(option.value)}>
          <OptionValue>
            {gameType === GameType.BAC && <Ball value={option.value} />}
            {gameType === GameType.HHH && <Dice value={option.value} size={DiceSize.Small} />}
          </OptionValue>
          <OptionLabel>{option.label}</OptionLabel>
        </OptionWrapper>
      )),
    [options, values, onChange, gameType],
  );

  return <Wrapper gameType={gameType}>{items}</Wrapper>;
};
