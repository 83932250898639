import { Button } from 'components/atoms';
import fontService, { FontWeight } from 'services/FontService';
import styled, { css } from 'styled-components';

export const Subtitle = styled.p`
  ${fontService.getFont({ size: 14, lineHeight: 143, weight: FontWeight.medium })};
`;

export const ResultsWrapper = styled.div`
  display: flex;
  gap: 12px;
  margin-top: 8px;
  margin-bottom: 32px;
`;

export const Result = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  min-width: 129px;
  padding: 24px 30px;
  border-radius: 8px;
  ${({ theme: { button, text } }) => css`
    border: 1px solid ${button.primaryBorderLight};
    color: ${text.secondaryDefault};
  `};
}`;

export const SelectWrapper = styled.div`
  margin-top: 8px;
`;

export const ButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 16px;
  justify-content: space-between;
  margin-top: 32px;
`;

export const ConfirmButton = styled(Button)``;

export const ClearButton = styled(Button)``;
