import { put, takeLatest, delay, select, race, take } from 'redux-saga/effects';
import { getGameData } from 'core/selectors';
import { ACTIONS } from 'data/constants';
import {
  decrementTimeAction,
  incrementTimeAction,
  startDecrementTimeLeftAction,
  stopDecrementTimeLeftAction,
  stopIncrementTimeLeftAction,
} from 'data/reducers';
import { ActionType } from 'typesafe-actions';

function* decrementTimeLeftSaga({
  payload: { type },
}: ActionType<typeof startDecrementTimeLeftAction>) {
  while (true) {
    const {
      round: { timerLeft },
    } = yield select(getGameData);

    if (timerLeft > 1) {
      const { stopped } = yield race({
        delay: delay(300),
        stopped: take(ACTIONS.STOP_DECREMENT_TIME_LEFT),
      });

      if (stopped) {
        break;
      }

      yield put(decrementTimeAction({ type }));
    } else {
      yield put(stopDecrementTimeLeftAction());
      break;
    }
  }
}

function* incrementTimeLeftSaga() {
  while (true) {
    const {
      round: { timerLeft },
    } = yield select(getGameData);

    if (timerLeft >= 0) {
      const { stopped } = yield race({
        delay: delay(1000),
        stopped: take(ACTIONS.STOP_INCREMENT_TIME_LEFT),
      });

      if (stopped) {
        break;
      }

      yield put(incrementTimeAction());
    } else {
      yield put(stopIncrementTimeLeftAction());
      break;
    }
  }
}

export function* watchTimeLeftSaga() {
  yield takeLatest(ACTIONS.START_DECREMENT_TIME_LEFT, decrementTimeLeftSaga);
  yield takeLatest(ACTIONS.START_INCREMENT_TIME_LEFT, incrementTimeLeftSaga);
}
