import { css } from 'styled-components';
import { ResultDice } from 'types';

export const diceStyles = {
  default: {
    [ResultDice.RED]: css`
      background: linear-gradient(180deg, #ff2455 39.38%, #991633 131.25%),
        linear-gradient(0deg, #ff2455, #ff2455);
      border: 1px solid #ff2455;
    `,
    [ResultDice.ORANGE]: css`
      background: linear-gradient(180deg, #ff6c2f 39.58%, #993816 131.94%),
        linear-gradient(0deg, #ff6c2f, #ff6c2f);
      border: 1px solid #ff6c2f;
    `,
    [ResultDice.YELLOW]: css`
      background: linear-gradient(180deg, #ffca41 39.17%, #a57703 130.56%),
        linear-gradient(0deg, #ffca41, #ffca41);
      border: 1px solid #ffca41;
    `,
    [ResultDice.GREEN]: css`
      background: linear-gradient(180deg, #a8d32c 39.17%, #576d17 130.56%),
        linear-gradient(0deg, #a8d32c, #a8d32c);
      border: 1px solid #a8d32c;
    `,
    [ResultDice.BLUE]: css`
      background: linear-gradient(180deg, #2bc5ef 39.17%, #197189 130.56%),
        linear-gradient(0deg, #2bc5ef, #2bc5ef);
      border: 1px solid #2bc5ef;
    `,
    [ResultDice.PURPLE]: css`
      background: linear-gradient(180deg, #a361f4 39.17%, #5f388e 130.56%),
        linear-gradient(0deg, #a361f4, #a361f4);
      border: 1px solid #a361f4;
    `,
    [ResultDice.UNDEFINED]: css`
      background: #32313b;
      border: 1px solid #dfe6f4;
    `,
  },
};
