import * as React from 'react';
import { memo, ReactNode } from 'react';

import styled, { css } from 'styled-components';

import fontService, { FontWeight } from 'services/FontService';

export enum LabelVariants {
  Default = 'Default',
  Active = 'Active',
  Maintenance = 'Maintenance',
  Error = 'Error',
}

export interface ILabel {
  variant?: LabelVariants;
  children: ReactNode;
  className?: string;
}

export const Label = memo(({ variant = LabelVariants.Default, children, className }: ILabel) => (
  <Wrapper variant={variant} className={className}>
    {children}
  </Wrapper>
));

const Wrapper = styled.span<ILabel>`
  ${fontService.text({ size: 14, lineHeight: 171, weight: FontWeight.bold })};
  display: inline-block;
  padding: 8px 24px;
  border-radius: 8px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: ${({ theme: { primaryTextColor } }) => primaryTextColor};
  ${({
    variant,
    theme: {
      tagPrimaryBGColor,
      tagPrimaryBorderColor,
      tagSecondaryBGColor,
      tagSecondaryBorderColor,
      tagTertiaryBGColor,
      tagTertiaryBorderColor,
      errorBorder,
      errorBGColor,
    },
  }) => {
    if (variant === LabelVariants.Active) {
      return css`
        background-color: ${tagSecondaryBGColor};
        border: 1px solid ${tagSecondaryBorderColor};
      `;
    }
    if (variant === LabelVariants.Default) {
      return css`
        background-color: ${tagPrimaryBGColor};
        border: 1px solid ${tagPrimaryBorderColor};
      `;
    }
    if (variant === LabelVariants.Maintenance) {
      return css`
        background-color: ${tagTertiaryBGColor};
        border: 1px solid ${tagTertiaryBorderColor};
      `;
    }
    if (variant === LabelVariants.Error) {
      return css`
        background-color: ${errorBGColor};
        border: 1px solid ${errorBorder};
      `;
    }
  }}
`;
