import { ResultBall, ResultDice, SelectOptionType } from 'types';

export const InfoPlaceholder = '-';

export const ShiftManagerIdLength = 5;
export const DealerIdLength = 3;

export const gameStatus = {
  OpenRound: 'Open round',
  BettingTime: 'Betting time',
  StartDraw: 'No more bets. Start draw',
  StartedDraw: 'Draw in progress',
  ConfirmResult: 'Round result',
  DisplayingWin: 'Round is over',
  Loading: 'Loading...',
};

export const ballOptions = [
  { value: ResultBall.BLUE, label: 'Blue' },
  { value: ResultBall.RED, label: 'Red' },
  { value: ResultBall.GOLD, label: 'Gold' },
];

export const dicesOptions: SelectOptionType<ResultDice>[] = [
  {
    value: ResultDice.RED,
    label: 'Red',
  },
  {
    value: ResultDice.ORANGE,
    label: 'Orange',
  },
  {
    value: ResultDice.YELLOW,
    label: 'Yellow',
  },
  {
    value: ResultDice.GREEN,
    label: 'Green',
  },
  {
    value: ResultDice.BLUE,
    label: 'Blue',
  },
  {
    value: ResultDice.PURPLE,
    label: 'Purple',
  },
];

export const responseType = {
  Error: 'ERROR',
  Ok: 'OK',
};

export const errorMessages = {
  IncorrectId: 'Incorrect ID',
  NotAvailableId: 'Not available ID',
  NotAvailableAction: 'Not available action',
  NoConnectionToMachine: 'No connection to machine',
  NoConnectionToAdmin: 'No connection to Admin Panel',
};

export const alerts = {
  TABLE_CLOSED: {
    text: `Table will be closed after round's end!`,
  },
  ROUND_CANCELED: {
    text: 'Last round was canceled in Admin Panel',
  },
};
