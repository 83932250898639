import styled, { css } from 'styled-components';
import fontService, { FontWeight } from 'services/FontService';
import { colors } from 'constants/theme';
import { Icon } from 'atoms';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const SelectInput = styled.div<{ disabled?: boolean; isOpened: boolean }>`
  position: relative;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${({ isOpened }) =>
    isOpened &&
    css`
      z-index: 10;
    `};
  color: ${({ theme: { text } }) => text.primaryDefault};
  ${fontService.text({ size: 12, lineHeight: 133, weight: FontWeight.medium })};
  cursor: pointer;

  ${({ disabled }) => {
    if (disabled) {
      return css`
        user-select: none;
        cursor: default;

        ${SelectedOption} {
          color: ${({ theme: { text } }) => text.primaryDisabled};
        }

        ${StyledIcon} {
          opacity: 0.3;
        }
      `;
    }
  }}
`;

export const SelectedOption = styled.span<{ selected: boolean }>`
  color: ${({ selected, theme: { text } }) =>
    selected ? text.primaryDefault : text.primaryDisabled};
`;

export const Dropdown = styled.div<{ isOpened: boolean; disabled?: boolean }>`
  box-sizing: border-box;
  width: 180%;
  max-height: 192px;
  overflow: scroll;
  ${fontService.text({ size: 14, lineHeight: 143, weight: FontWeight.regular })}
  box-shadow: 0 4px 24px 0 rgba(18, 16, 17, 0.4);
  background-color: ${({ theme: { background } }) => background.primaryLight};
  border-radius: 8px;
  padding: 6px 0;
  margin: 8px 0 0 0;
  z-index: 100;
  position: absolute;
  right: 0;
  cursor: pointer;
  ::-webkit-scrollbar-track {
    display: none;
  }
  ${({ isOpened }) =>
    isOpened
      ? css`
          transform: translateY(0);
          visibility: visible;
          opacity: 1;
          transition: all 0.3s ease-in-out;
        `
      : css`
          transform: translateY(-20px);
          visibility: hidden;
          opacity: 0;
          transition: all 0.3s ease-in-out;
        `}

  ${({ disabled }) =>
    disabled &&
    css`
      display: none;
    `}
`;

export const DropdownItem = styled.li<{ selected: boolean }>`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  height: 36px;
  padding: 12px;
  color: ${({ theme: { text } }) => text.secondaryDefault};
  ${({ selected, theme: { text } }) =>
    selected &&
    css`
      background-color: ${colors.Dark};
      color: ${text.primaryDefault};
    `}

  &:hover {
    background-color: ${colors.Dark};
  }
`;

export const StyledIcon = styled(Icon)`
  width: 10px;
  height: 10px;
`;
